import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <ul className="icons">
                    <li><a href="mailto:info@qmgfl.com" className="icon alt fa-envelope"><span className="label">Email</span></a></li>
                </ul>
                <ul className="copyright">
                    <li>&copy; Quality Marketing Group Inc.</li><li>Home: <a href="http://qmgfl.com">www.qmgfl.com</a></li>
                </ul>

                <ul className="copyright">
                    <li>725 W State Road 434 Ste E</li><li>Longwood FL 32750-5157</li>
                </ul>

                <ul className="copyright">
                    <li>877-744-9064</li><li>info@qmgfl.com</li>
                </ul>



            </section>
        )
    }
}

export default Footer
