import React from 'react'
import qmglogo from '../assets/images/qmg_logo_title.png'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <div className="inner">
                    <span><img src={qmglogo} alt="Quality Marketing Group" /></span>
                    <h1>We are <strong>Quality Marketing Group</strong>, cutting edge marketing services that represent our own brands as well as third party clients</h1>
                    <ul className="actions">
                        <li><a href="#one" className="button scrolly">Discover</a></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Header
